import React from 'react';
import BaseForm from './BaseForm.jsx';
import PlantNameInput from './inputs/PlantNameInput.jsx';
import PlantLocationInput from './inputs/PlantLocationInput.jsx';
import Button from './Button.jsx';
import { Form } from '../styles/Common.js';

export default class EditPlantForm extends BaseForm {
  state = {
    showValidation: false,
    fields: {
      name: {
        value: this.props.plantData.name,
        isValid: true
      },
      location: {
        value: this.props.plantData.location,
        isValid: true
      },
      id: {
        value: this.props.plantData._id,
        isValid: true
      },
      wateredDates: {
        value: this.props.plantData.wateredDates,
        isValid: true
      },
      fertilisedDates: {
        value: this.props.plantData.fertilisedDates,
        isValid: true
      }
    }
  }

  render() {
    const { fields, showValidation } = this.state;

    return (
      <>
        <Form onSubmit={this.handleSubmit} noValidate>
          <PlantNameInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.name.value}
          />

          <PlantLocationInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.location.value}
          />

          <Button isSubmitting={this.props.isSubmitting}>Update Plant</Button>
        </Form>
      </>
    );
  }
}
