import React from 'react';
import { Redirect } from 'react-router-dom';
import SubscribeButton from '../components/SubscribeButton.jsx';
import PricingFeatures from '../components/PricingFeatures.jsx';
import { CTAButton, P, FixedFooterButton } from '../styles/Common.js';
import styled from 'styled-components';

const Paragraph = styled(P)`
  margin-bottom: 3rem;
`;

const IconAttribution = styled.div`
  color: #cacaca;
  font-size: 1.2rem;
  left: 0;
  margin-top: 4rem;
  text-align: center;

  a {
    color: #cacaca;
    text-decoration: none;

    &:hover {
      color: #cacaca;
    }
  }
`;

export default ({
  checkingAuth,
  email,
  isAuthed,
  isSubscribed,
  userId
}) => {
  if (checkingAuth) return <p>Loading</p>;
  if (!isAuthed) return <Redirect to="/log-in" />;

  const basicFeatures = [
    { text: 'Add unlimited plants' },
    { text: 'Log watered dates' },
    { text: 'Log fertilised dates' },
    { text: 'Calendar view of plant activity' }
  ];

  const proFeatures = [
    ...basicFeatures,
    { text: 'Add daily notes to each plant', soon: true },
    { text: 'Add plant DOB', soon: true },
    { text: 'Add repotted date', soon: true },
    { text: 'Add a picture', soon: true }
  ];

  let el = (
    <>
      <PricingFeatures
        title="Plant Parent"
        price="3.99"
        pricePerDay="About 13c per day"
        icons={['BoP', 'Monstera', 'Ficus']}
        description="Join other plant parents in giving your leafy friends the best possible care"
        features={proFeatures}
      >
        <SubscribeButton userId={userId} email={email} style={CTAButton}>
          Get new features
        </SubscribeButton>
      </PricingFeatures>
      <PricingFeatures
        icons={['Monstera']}
        description="Continue to monitor your plants watering for free"
        features={basicFeatures}
      />
      <FixedFooterButton>
        <SubscribeButton userId={userId} email={email} style={CTAButton}>
          Get new features
        </SubscribeButton>
      </FixedFooterButton>
    </>
  );

  if (isSubscribed) {
    el = (
      <>
        <Paragraph>You are already subscribed and getting these features</Paragraph>
        <PricingFeatures
          title="Plant Parent"
          price="3.99"
          pricePerDay="About 13c per day"
          icons={['BoP', 'Monstera', 'Ficus']}
          features={proFeatures}
        />
      </>
    )
  }

  return (
    <>
      {el}
      <IconAttribution>Plant icons made by Darius Dan from www.flaticon.com</IconAttribution>
    </>
  );
}
