import React from 'react';
import styled from 'styled-components';
import { H3, P } from '../styles/Common.js';

const AnnouncementContainer = styled.div`
  background: teal;
  margin: 0 auto 2rem;
  max-width: 37rem;
  padding: 2rem;
  position: relative;

  @media (max-width: 420px) {
    max-width: 100%;
  }

  @media (max-width: 370px) {
    max-width: 100%;
  }
`;

const Heading = styled(H3)`
  color: #fff;
`;

const Text = styled(P)`
  color: #fff;
  margin-bottom: 0;
`;

const Close = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 2.2rem;
  height: 4.5rem;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.5rem;
`;

export default ({
  title,
  message,
  clearAnnouncements
}) => {
  if (!title && !message) return null;

  return (
    <AnnouncementContainer>
      <Close onClick={() => clearAnnouncements()}>&times;</Close>
      <Heading>{title}</Heading>
      <Text>{message}</Text>
    </AnnouncementContainer>
  )
}
