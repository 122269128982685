import React, { Component } from 'react';
import AddPlantForm from '../components/AddPlantForm.jsx';
import api from '../api.js';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

export default class AddPlantPage extends Component {
  state = {
    isSubmitting: false
  }

  addNewPlant = plant => {
    const id = this.props.user.id;
    const token = this.props.user.token;

    this.setState({ isSubmitting: true });

    api.addPlant(id, plant, token)
    .then(plants => {
      this.props.updateState({
        plants: {
          loading: false,
          list: plants
        }
      });
      this.props.history.push('/');
    })
    .catch(error => {
      console.log('⛔️ ', error);
      toast.error(error.message);
    })
    .finally(() => this.setState({ isSubmitting: false }));
  }

  render() {
    if (this.props.checkingAuth) return <p>Loading</p>;
    if (!this.props.isAuthed) return <Redirect to="/log-in" />;

    return <AddPlantForm onSubmit={this.addNewPlant} isSubmitting={this.state.isSubmitting} />;
  }
}
