import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Announcement from './components/Announcement.jsx';
import MainNav from './components/MainNav.jsx';
import SubNav from './components/SubNav.jsx';
import NotFoundPage from './pages/NotFoundPage.jsx';
import HomePage from './pages/HomePage.jsx';
import LoginPage from './pages/LoginPage.jsx';
import RegisterPage from './pages/RegisterPage.jsx';
import AccountPage from './pages/AccountPage.jsx';
import AddPlantPage from './pages/AddPlantPage.jsx';
import EditPlantPage from './pages/EditPlantPage.jsx';
import PlantInfoPage from './pages/PlantInfoPage.jsx';
import ForgotPasswordPage from './pages/ForgotPasswordPage.jsx';
import ForgotPasswordResetPage from './pages/ForgotPasswordResetPage.jsx';
import SubscribePage from './pages/SubscribePage.jsx';
import api from './api.js';
import Cookies from 'cookies-js';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import backgroundImage from './assets/plant.jpg';
import { InnerContainer } from './styles/Common.js';
import { getQueryStringParameter } from './helpers.js';

const Main = styled.main`
  background-attachment: fixed;
  background-image: url(${backgroundImage});
  min-height: 100vh;
  padding: 2rem 0 5rem;

  form {
    opacity: ${props => props.isLoading ? 0.3 : 1};
  }
`;

const defaultState = {
  checkingAuth: true,
  formIsLoading: false,
  announcement: false,
  user: {
    id: null,
    token: null,
    email: null,
    isSubscribed: false,
    stripeSubscriptionId: null
  },
  plants: {
    loading: true,
    list: [],
    sortBy: 'watered' // Comes from server as name by default
  }
};

export default class App extends Component {
  state = defaultState

  componentWillMount = () => {
    const stripeQuery = getQueryStringParameter('stripe');
    if (stripeQuery === 'subscribed') {
      this.setState({
        announcement: {
          title: 'Subscibed',
          message: 'Thanks for subscribing. If you have any feedback, questions or suggestions please get in touch at houseplanttracker@gmail.com'
        }
      })
    }
  }

  componentDidMount = () => {
    // Checking for existing session on load/refresh
    const { id, token } = JSON.parse(Cookies.get('session') || '{}');
    if (id && token) {
      this.updateAuthedState(id, token);
    } else {
      this.setState({ checkingAuth: false });
    }
  }

  clearAnnouncements = () => {
    this.setState({ announcement: false });
  }

  sortByName = (list) => {
    const listToSort = list || this.state.plants.list;
    const updatedList = listToSort.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // names must be equal
    });

    this.updateListSort(updatedList, 'name');
  }

  sortByLocation = (list) => {
    const listToSort = list || this.state.plants.list;
    const updatedList = listToSort.sort((a, b) => {
      const nameA = a.location.toUpperCase();
      const nameB = b.location.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // names must be equal
    });

    this.updateListSort(updatedList, 'location');
  }

  sortByLastWatered = (list) => {
    const listToSort = list || this.state.plants.list;

    const updatedList = listToSort.sort((a, b) => {
      const nameA = a.wateredDates[a.wateredDates.length - 1] || '1970-01-01T00:00:00.000Z';
      const nameB = b.wateredDates[b.wateredDates.length - 1] || '1970-01-01T00:00:00.000Z';

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // names must be equal
    });

    this.updateListSort(updatedList, 'watered');
  }

  updateListSort = (list, sortBy) => {
    this.setState({
      plants: {
        ...this.props.plants,
        loading: false,
        list,
        sortBy
      }
    });

    Cookies.set('sortBy', sortBy);
  }

  checkForSort = (plantsList) => {
    const savedSortBy = Cookies.get('sortBy');
    switch (savedSortBy) {
      case 'watered':
        this.sortByLastWatered(plantsList)
        break;
      case 'name':
        this.sortByName(plantsList);
        break;
      case 'location':
        this.sortByLocation(plantsList);
        break;
      default:
        this.sortByLastWatered(plantsList)
    }
  }

  updateAuthedState = (id, token) => {
    if (id && token) {
      api.getUser(id, token)
      .then(user => {
        const hasCurrentPeriodEnd = !!user.currentPeriodEnd;
        const isSubscribed = hasCurrentPeriodEnd && new Date(user.currentPeriodEnd) > new Date();
        this.setState({
          checkingAuth: false,
          user: {
            ...this.state.user,
            id,
            token,
            email: user.email,
            isSubscribed,
            stripeSubscriptionId: user.stripeSubscriptionId,
            currentPeriodEnd: user.currentPeriodEnd
          }
        });
        this.checkForSort(user.plants);
      })
      .catch(error => {
        if (error.status === 401) this.logout();
        toast.error(error.message);
      });
    }
  }

  updateUserState = (user) => {
    const { currentPeriodEnd, email, stripeSubscriptionId } = user;
    const hasCurrentPeriodEnd = !!currentPeriodEnd;
    const isSubscribed = hasCurrentPeriodEnd && new Date(currentPeriodEnd) > new Date();

    this.setState({
      user: {
        ...this.state.user,
        email,
        isSubscribed,
        stripeSubscriptionId
      }
    })
  }

  logout = () => {
    api.logout()
      .then(res => {
        Cookies.expire('session');
        this.setState(defaultState);
        window.location = '/log-in';
      })
      .catch((error) => toast.error(error));
  }

  authorise = (method, user) => {
    return new Promise((resolve) => {
      this.setState({ formIsLoading: true });
      api[method](user)
      .then(({ id, message, token}) => {
        const sessionData = JSON.stringify({ token, id });
        Cookies.set('session', sessionData);
        this.updateAuthedState(id, token);
        resolve();
      })
      .catch((error) => toast.error(error.message))
      .finally(() => this.setState({ formIsLoading: false }));
    });
  }

  updateState = (state) => this.setState(state);

  render() {
    const { user, checkingAuth } = this.state;
    const isAuthed = user.id && user.token;

    return (
      <Router>
        <SubNav
          isAuthed={isAuthed}
          isSubscribed={this.state.user.isSubscribed}
          userId={user.id}
          email={this.state.user.email}
          />
        <Main isLoading={this.state.formIsLoading}>
          <Announcement {...this.state.announcement} clearAnnouncements={this.clearAnnouncements}/>
          <MainNav isAuthed={isAuthed} />
          <InnerContainer>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <HomePage
                    checkingAuth={checkingAuth}
                    plants={this.state.plants}
                    isAuthed={isAuthed}
                    sortByLocation={this.sortByLocation}
                    sortByName={this.sortByName}
                    sortByLastWatered={this.sortByLastWatered}
                    checkForSort={this.checkForSort}
                  />
                )}
              />
              <Route
                path="/log-in"
                render={() => (
                  <LoginPage
                    authorise={this.authorise}
                    isAuthed={isAuthed}
                  />
                )}
              />
              <Route
                path="/register"
                render={() => (
                  <RegisterPage
                    authorise={this.authorise}
                    isAuthed={isAuthed}
                  />
                )}
              />
              <Route
                path="/account"
                render={() => (
                  <AccountPage
                    checkingAuth={checkingAuth}
                    isAuthed={isAuthed}
                    plants={this.state.plants}
                    user={this.state.user}
                    logout={this.logout}
                    updateUserState={this.updateUserState}
                  />
                )}
              />
              {window.config.enableSubscriptions &&
                <Route
                  path="/subscribe"
                  render={() => (
                    <SubscribePage
                      checkingAuth={checkingAuth}
                      email={this.state.user.email}
                      isAuthed={isAuthed}
                      isSubscribed={this.state.user.isSubscribed}
                      userId={user.id}
                    />
                  )}
                />
              }
              <Route
                path="/plant/add"
                render={(props) => (
                  <AddPlantPage
                    {...props}
                    checkingAuth={checkingAuth}
                    updateState={this.updateState}
                    plants={this.state.plants}
                    user={this.state.user}
                    isAuthed={isAuthed}
                  />
                )}
              />
              <Route
                path="/plant/info/:id"
                render={(props) => (
                  <PlantInfoPage
                    {...props}
                    checkingAuth={checkingAuth}
                    updateState={this.updateState}
                    plants={this.state.plants}
                    user={this.state.user}
                    isAuthed={isAuthed}
                    plantId={props.match.params.id}
                  />
                )}
              />
              <Route
                path="/plant/edit/:id"
                render={(props) => (
                  <EditPlantPage
                    {...props}
                    checkingAuth={checkingAuth}
                    updateState={this.updateState}
                    plants={this.state.plants}
                    user={this.state.user}
                    isAuthed={isAuthed}
                    plantId={props.match.params.id}
                  />
                )}
              />
              <Route
                path="/forgotpassword"
                render={() => (
                  <ForgotPasswordPage
                    isAuthed={isAuthed}
                  />
                )}
              />
              <Route
                path="/reset/:token"
                render={(props) => (
                  <ForgotPasswordResetPage
                    isAuthed={isAuthed}
                    resetPasswordToken={props.match.params.token}
                  />
                )}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </InnerContainer>
        </Main>
        <ToastContainer />
      </Router>
    );
  }
}
