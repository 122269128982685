import React from 'react';
import InputGroup from '../InputGroup.jsx';

export default ({
  onFieldValidate,
  onChange,
  showValidation,
  value
}) => (
  <InputGroup
    label="Location"
    placeholder="e.g. Living Room"
    type="text"
    onFieldValidate={onFieldValidate}
    onChange={onChange}
    showValidation={showValidation}
    stateName="location"
    value={value}
    validation={{
      required: true,
      numberRange: {
        min: 1,
        max: 30
      }
    }}
    attributes={{
      'data-hj-whitelist': '',
      required: true
    }}
  />
);
