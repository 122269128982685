import React, { Component } from 'react';
import PlantTitleGroup from './PlantTitleGroup.jsx';
import Calendar from './Calendar.jsx';
import Button from './Button.jsx';
import { isEqual } from '../helpers.js';
import { FixedFooterButton } from '../styles/Common.js'

export default class PlantInfo extends Component {
  state = {
    wateredDates: this.props.plantData.wateredDates,
    fertilisedDates: this.props.plantData.fertilisedDates,
    hasChanges: false
  }

  updateWateringState = (wateredDates) => {
    this.setState({ wateredDates }, () => this.checkForChanges());
  }

  updateFertilisedState = (fertilisedDates) => {
    this.setState({ fertilisedDates }, () => this.checkForChanges());
  }

  checkForChanges = () => {
    const { wateredDates, fertilisedDates } = this.state;
    const wateredHasChanges = !isEqual(wateredDates, this.props.plantData.wateredDates);
    const fertilisedHasChanges = !isEqual(fertilisedDates, this.props.plantData.fertilisedDates);
    const hasChanges = wateredHasChanges || fertilisedHasChanges;
    this.setState({ hasChanges });
  }

  saveChanges = e => {
    e.preventDefault();
    if (this.state.hasChanges) {
      let { wateredDates, fertilisedDates } = this.state;

      // Add time on so date doesn't appear -1 in the DB (https://github.com/Automattic/mongoose/issues/756)
      wateredDates = wateredDates.map(date => `${date} 00:00:00.000Z`);
      fertilisedDates = fertilisedDates.map(date => `${date} 00:00:00.000Z`);
      const toSubmit = {
        id: this.props.plantData._id,
        wateredDates,
        fertilisedDates
      };

      this.props.onSubmit(toSubmit);
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <>
        <PlantTitleGroup {...this.props.plantData} edit={this.props.plantData._id} />

        <Calendar
          wateredDates={this.state.wateredDates}
          fertilisedDates={this.state.fertilisedDates}
          updateWateringState={this.updateWateringState}
          updateFertilisedState={this.updateFertilisedState}
        />

        <FixedFooterButton>
          <Button
            onClick={this.saveChanges}
            isSubmitting={this.props.isSubmitting}
          >
            Save changes
          </Button>
        </FixedFooterButton>
      </>
    )
  }
}
