import React, { Component } from 'react';
import styled from 'styled-components';
import validation from '../validation.js';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 7.7rem;
  margin-bottom: 1.6rem;
`;

const Label = styled.label`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.6rem;
`;

const Input = styled.input`
  background: #efefef;
  border: none;
  font-size: 1.4rem;
  padding: 1rem;
`;

const Error = styled.span`
  color: #8f0000;
  font-size: 1.4rem;
  line-height: 1;
  margin-top: 0.6rem;
`;

export default class InputGroup extends Component {
  state = {
    errorMessages: [],
    isClean: true,
    isValid: false
  }

  componentDidMount() {
    // On load we validate the fields but don't show anything as `showValidation` is false from the form/parent
    // This allows us to show validation if the user hits submit on the form without
    // editing a field by changing the `showValidation` prop on the form/parent
    this.validate('', true);
  }

  onChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    const keyCode = event.code;
    this.props.onChange(value, this.props.stateName, keyCode);
    this.validate(value);
    // if (!this.state.isClean || this.props.showValidation) this.validate(value);
  }

  onKeyUp = (event) => {
    if (event.keyCode === 13) this.validate(this.props.value);
  }

  onBlur = (event) => {
    const value = event.target.value;
    if (this.state.isClean && value) {
      this.setState({
        isClean: false
      }, () => {
        this.validate(value);
      });
    } else if (!this.state.isClean) {
      this.validate(value);
    }
  }

  validate = (value = '', isInit = false) => {
    let val = value;
    if (isInit) val = this.props.value; // So we don't get val message for amount when there's a default val
    const validationTypes = this.props.validation || {};
    const errors = validation.validate(val, validationTypes);
    const isValid = !!!errors.length;
    this.setState({
      errorMessages: errors,
      isValid
    });

    if (!isInit) this.props.onFieldValidate(isValid, this.props.stateName);
  }

  getValidationMessages = () => {
    const errorMessages = this.state.errorMessages;
    const showValidation = this.props.showValidation;
    const isClean = this.state.isClean;
    return (errorMessages.length && showValidation) || (errorMessages.length && !isClean) ?
      <Error>{errorMessages[0]}</Error> : null;
  }

  render() {
    const {
      label,
      placeholder,
      stateName,
      type,
      value,
      attributes
    } = this.props;

    return (
      <InputContainer>
        <Label htmlFor={stateName}>{label}</Label>
        <Input
          name={stateName}
          id={stateName}
          placeholder={placeholder}
          type={type}
          value={value}
          onKeyUp={(e) => this.onKeyUp(e)}
          onChange={(e) => this.onChange(e)}
          onBlur={(e) => this.onBlur(e)}
          {...attributes}
        />
        {this.getValidationMessages()}
      </InputContainer>
    );
  }
}
