import React from 'react';
import { Redirect } from 'react-router-dom';
import PlantsList from '../components/PlantsList.jsx';

export default ({
  addNewPlant,
  checkForSort,
  checkingAuth,
  isAuthed,
  plants,
  sortByLocation,
  sortByName,
  sortByLastWatered
}) => {
  if (checkingAuth) return <p>Loading</p>;
  if (!isAuthed) return <Redirect to="/log-in" />;
  return (
    <>
      <PlantsList
        checkForSort={checkForSort}
        plants={plants}
        sortByLocation={sortByLocation}
        sortByName={sortByName}
        sortByLastWatered={sortByLastWatered}
      />
    </>
  );
}
