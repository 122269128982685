import React, { Component } from 'react';
import api from '../api.js';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SubscriptionArea from '../components/SubscriptionArea.jsx';
import { TextButton, TextLink, H2 } from '../styles/Common.js';

const FeedbackLink = styled(TextLink)`
  display: inline-block;
  margin-bottom: 1rem;
  text-decoration: none;
`;

export default class AccountPage extends Component {
  state = {
    isSubmitting: false
  }

  cancelSubscription = () => {
    const { id, stripeSubscriptionId, email } = this.props.user;

    this.setState({ isSubmitting: true });

    api.cancelStripeSubscription({
      email,
      id,
      stripeSubscriptionId
    })
    .then(data => {
      this.props.updateUserState(data.user);
    })
    .catch(error => {
      toast.error(error.message);
    })
    .finally(() => this.setState({ isSubmitting: false }));
  }

  render() {
    if (this.props.checkingAuth) return <p>Loading</p>;
    if (!this.props.isAuthed) return <Redirect to="/log-in" />;

    return (
      <>
        <H2>Account</H2>
        <SubscriptionArea
          cancelSubscription={this.cancelSubscription}
          isSubmitting={this.state.isSubmitting}
          user={this.props.user}
        />
        <FeedbackLink
          href="mailto:houseplanttracker@gmail.com?subject=Feedback"
          target="_blank"
        >
          Send feedback
        </FeedbackLink>
        <TextButton onClick={this.props.logout}>Log out</TextButton>
      </>
    );
  }
}
