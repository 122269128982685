import React, { Component } from 'react';
import LoginForm from '../components/LoginForm.jsx';
import HOCAuthed from '../components/HOCAuthed.js';

const LoginComponent = class LoginPage extends Component {
  login = user => {
    this.props.action('login', user);
  }

  render() {
    return <LoginForm onSubmit={this.login} />;
  }
}

export default HOCAuthed(LoginComponent);
