import React, { Component } from 'react';
import EditPlantForm from '../components/EditPlantForm.jsx';
import api from '../api.js';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

export default class EditPlantPage extends Component {
  state = {
    isSubmitting: false
  }

  updatePlant = plant => {
    const id = this.props.user.id;
    const token = this.props.user.token;

    this.setState({ isSubmitting: true });

    api.updatePlant(id, plant, token)
    .then(plants => {
      this.props.history.push(`/plant/info/${plant.id}`);
      this.props.updateState({
        plants: {
          loading: false,
          list: plants
        }
      })
    })
    .catch(error => {
      console.log('⛔️ ', error);
      toast.error(error);
    })
    .finally(() => this.setState({ isSubmitting: false }));
  }

  convertDatesFromISO = (dates) => {
    return dates.map(date => {
      // Note to self: Don't work with dates again 🙅‍♂️
      // https://stackoverflow.com/questions/2587345/why-does-date-parse-give-incorrect-results
      // U.S. users were recording a day, only for it to be displayed as the previous day
      const parts = date.split('-');
      const fixedDate = new Date(parts[0], parts[1]-1, parts[2].split('T')[0])
      return fixedDate.toLocaleDateString('en-US');
    }); // returns MM/DD/YYYY
  }

  render() {
    const { checkingAuth, isAuthed, plants, plantId } = this.props;
    if (checkingAuth) return <p>Loading</p>;
    if (!isAuthed) return <Redirect to="/log-in" />;
    if (plants.loading) return 'Loading';
    // Spreading so we don't alter the app state
    const plantData = {...plants.list.find(plant => plant._id === plantId)};
    if (!plantData) return 'No plant';

    plantData.wateredDates = this.convertDatesFromISO(plantData.wateredDates);
    plantData.fertilisedDates = this.convertDatesFromISO(plantData.fertilisedDates);

    return <EditPlantForm onSubmit={this.updatePlant} plantData={plantData} isSubmitting={this.state.isSubmitting} />;
  }
}
