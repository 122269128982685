import React from 'react';
import InputGroup from '../InputGroup.jsx';

export default ({
  onFieldValidate,
  onChange,
  showValidation,
  value
}) => (
  <InputGroup
    label="Email"
    placeholder="Email"
    type="email"
    onFieldValidate={onFieldValidate}
    onChange={onChange}
    showValidation={showValidation}
    stateName="email"
    value={value}
    validation={{
      required: true,
      email: true
    }}
    attributes={{
      'data-hj-masked': '',
      autoCorrect: 'off',
      autoComplete: 'off',
      required: true
    }}
  />
);
