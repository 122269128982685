import { Component } from 'react';

export default class BaseForm extends Component {
  isFormValid = () => {
    const fieldsArray = [];
    const fields = this.state.fields;
    Object.entries(fields).forEach(item => fieldsArray.push(item[1].isValid));
    return fieldsArray.every(item => item === true);
  }

  onFieldValidate = (isValid, field) => {
    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: {
          ...prevState.fields[field],
          isValid
        }
      }
    }));
  }

  onChange = (value, field) => {
    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [field]: {
          ...prevState.fields[field],
          value
        }
      }
    }));
  }

  getFieldValues = () => {
    const formData = {};
    const fields = this.state.fields;
    Object.entries(fields).forEach(item => (formData[item[0]] = item[1].value));
    return formData;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ showValidation: true });

    if (this.isFormValid()) {
      const payload = this.getFieldValues();
      this.props.onSubmit(payload);
    }
  }
}
