import React from 'react';
import { CTAButton } from '../styles/Common.js';

export default ({
  children,
  isSubmitting,
  onClick
}) => {
  let inner = children;
  if (isSubmitting) inner = 'Loading';
  return (
    <CTAButton onClick={onClick}>
      <span>{inner}</span>
    </CTAButton>
  );
}
