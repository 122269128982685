export default {
  /**
   *  Validation setup
   */
  validationMethods() {
    return {
      email: this.email.bind(this),
      numberRange: this.numberRange.bind(this),
      required: this.required.bind(this)
    };
  },

  validate(value, validationTypes) {
    const validationMethods = this.validationMethods();
    const errors = [];

    Object.entries(validationTypes).forEach(item => {
      const validationValue = item[1];
      const validationType = item[0];
      // if (_.isFunction(validationMethods[validationType])) {
        const error = validationMethods[validationType](value, validationValue);
        if (error) errors.push(error);
      // }
    });

    return errors;
  },

  /**
   *  Validation methods
   */
  email(value) {
    let msg = false;
    const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    if (!regex.test(value)) msg = 'Invalid email';
    return msg;
  },

  numberRange(value, ranges) {
    const { min, max } = ranges;
    let msg = false;
    if (value.length < min) msg = `Use ${min} characters or more`;
    if (value.length > max) msg = `Use ${max} characters or less`;
    return msg;
  },

  required(value) {
    let msg = false;
    if (!value) msg = msg = 'Field required';
    return msg;
  }
};
