import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getLastWateredDate, getLastFertilisedDate } from '../helpers.js';
import Cookies from 'cookies-js';
import styled from 'styled-components';
import { TextLink } from '../styles/Common.js';

const PlantGrid = styled.ul`
  display: flex;
  flex-direction: column;
`;

const PlantTile = styled.li`
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #0080801a;
  padding-bottom: 1.5rem;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    transition: transform 0.1s ease;

    &:hover {
      transform: translateX(0.2rem);
    }
  }
`;

const InfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Name = styled.span`
  color: #4a4a4a;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1rem);
`;

const Room = styled.span`
  color: #7f7f80;
  font-size: 1.2rem;
  margin-top: 0.3rem;
`;

const LastWatered = styled.span`
  font-size: 1.1rem;
  background: #f0f3ff;
  color: #7299ff;
  border-radius: 1rem;
  padding: 0.4rem 0.8rem;
`;

const LastFertilised = styled.span`
  font-size: 1.1rem;
  background: #fbf3e9;
  color: #da9952;
  border-radius: 1rem;
  padding: 0.4rem 0.8rem;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.6rem;

  > span {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const EmptyList = styled.p`
  line-height: 1.4;
  margin: 0;
  text-align: center;

  a {
    display: block;
  }
`;

const SortContainer = styled.div`
  align-items: center;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  overflow: hidden;

  p {
    font-size: 1.2rem;
    margin: 0 0.4rem 0 0;
  }

  button {
    border: none;
    background: #eaeaea;
    color: teal;
    flex: 1 1 auto;
    font-size: 1.2rem;
    padding: 1rem 0.5rem;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }

    &.active {
      background: teal;
      color: white;
    }
  }
`;

export default class PlantsList extends Component {
  updateListState = (list, sortBy) => {
    this.props.updateState({
      plants: {
        ...this.props.plants,
        list,
        sortBy
      }
    });
  }

  getList = () => {
    const list = this.props.plants.list.map((plant, i) => {
      const lastWatered = getLastWateredDate(plant.wateredDates);
      const lastFertilised = getLastFertilisedDate(plant.fertilisedDates);

      return (
        <PlantTile key={i}>
          <Link to={`plant/info/${plant._id}`}>
            <InfoContainer>
              <Name>{plant.name}</Name>
              <Room>{plant.location}</Room>
              <Tags>
                <LastWatered>{lastWatered}</LastWatered>
                <LastFertilised>{lastFertilised}</LastFertilised>
              </Tags>
            </InfoContainer>
          </Link>
        </PlantTile>
      )
    });

    if (!list.length) {
      return (
        <EmptyList>Looking lonely over here. <TextLink to={`/plant/add`}>Add some plants <span role="img" aria-label="Plant emoji">🌿🌱</span></TextLink></EmptyList>
      );
    }

    return list;
  }

  getSortOptions = () => {
    if (!this.props.plants.list.length) return null;

    const sortBy = this.props.plants.sortBy;

    return (
      <SortContainer>
        <button
          onClick={() => this.props.sortByLastWatered()}
          className={sortBy === 'watered' ? 'active' : ''}
        >
          Last watered
        </button>
        <button
          onClick={() => this.props.sortByName()}
          className={sortBy === 'name' ? 'active' : ''}
          >
          A-Z Name
        </button>
        <button
          onClick={() => this.props.sortByLocation()}
          className={sortBy === 'location' ? 'active' : ''}
        >
          A-Z Location
        </button>
      </SortContainer>
    );
  }

  render() {
    if (this.props.plants.loading) return 'Loading...';

    const sortBy = this.props.plants.sortBy;
    const savedSortBy = Cookies.get('sortBy');
    if (savedSortBy !== sortBy) this.props.checkForSort(this.props.plants.list)
    const sortOptions = this.getSortOptions();
    return (
      <>
        {sortOptions}
        <PlantGrid>{this.getList()}</PlantGrid>
      </>
    );
  }
}
