import React, { useEffect } from 'react';
import {withRouter} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Profile from './icons/Profile.jsx';

const SubNavContainer = styled.nav`
  background: #111;
  display: flex;
  height: 4rem;

  div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 37rem;
    width: 100%

    @media (max-width: 420px) {
      max-width: 100%;
    }
  }
`;

const ProfileLink = styled(NavLink)`
  padding: 1rem;
`;

const Logo = styled(Link)`
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0 auto 0 0;
  text-decoration: none;

  @media (max-width: 420px) {
    margin-left: 2rem;
  }
`;

const SubButton = styled(Link)`
  align-items: center;
  background: gold;
  color: #111;
  display: flex;
  font-size: 1.3rem;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.1rem;
  padding: 1rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
`;

const SubNav = ({ isAuthed, isSubscribed, ...props }) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (props.location.pathname === '/subscribe') {
      body.classList.add('sub-page');
    } else {
      body.classList.remove('sub-page');
    }
  });

  if (!isAuthed) return null;

  let el = null;
  if (window.config.enableSubscriptions && !isSubscribed && props.location.pathname !== '/subscribe') {
    el = <SubButton to="/subscribe">Subscribe</SubButton>;
  }

  return (
    <SubNavContainer>
      <div>
        <Logo to="/">House Plant Tracker</Logo>
        {el}
        <ProfileLink exact to="/account">
          <Profile />
        </ProfileLink>
      </div>
    </SubNavContainer>
  )
};

export default withRouter(props => <SubNav {...props}/>);
