// https://stripe.com/docs/payments/checkout/subscriptions/starting

import React, { Component } from 'react';
import api from '../api.js';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const isDevMode = window.location.hostname === 'localhost';
const { stripePublicKeyTest, stripePublicKeyLive } = window.config;
const stripePublicKey = isDevMode ? stripePublicKeyTest : stripePublicKeyLive;
const stripe = window.Stripe(stripePublicKey);

const Button = styled.button`
  background: none;
  border: none;
  color: teal;
  cursor: pointer;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default class SubscribeButton extends Component {
  state = {
    session: false
  }

  getSessionId() {
    api.getStripeSessionId({
      email: this.props.email,
      userId: this.props.userId
    }).then(session => {
      this.setState({ session });
    });
  }

  render() {
    if (this.state.session) {
      stripe.redirectToCheckout({
        sessionId: this.state.session.session.id
      }).then(result => {
        console.log(':: result ', result);
        if (result && result.error && result.error.message) {
          toast.error(result.error.message);
        }
      })
    }

    const Style = this.props.style || Button;

    return (
      <Style onClick={() => this.getSessionId()}>{this.props.children || 'Subscribe'}</Style>
    )
  }
}
