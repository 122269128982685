import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import { format } from 'date-fns';
import 'react-day-picker/lib/style.css';
import { CheckboxPill } from '../styles/Common.js';

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  .calendar-container {
    border: 1px solid #ebebebeb;
    border-radius: 0.5rem;
    margin: 0 auto;
    position: relative;
    padding-top: 1rem;

    &:focus {
      outline: none;
    }

    .DayPicker-Day--watered,
    .DayPicker-Day--fertilised {
      &::before,
      &::after {
        content: '';
        width: 0.6rem;
        height: 0.6rem;
        display: block;
        position: absolute;
        bottom: 0.3rem;
        border-radius: 2rem;
      }
    }

    .DayPicker-Day--watered {
      &::before {
        background-color: #93beec;
        left: calc(50% - 0.4rem);
      }
    }

    .DayPicker-Day--fertilised {
      &::after {
        background-color: #ecc193;
        right: calc(50% - 0.4rem);
      }
    }

    .DayPicker-Day--fertilised {
      &.DayPicker-Day--watered {
        &::before {
          left: calc(50% - 0.8rem);
        }

        &::after {
          right: calc(50% - 0.8rem);
        }
      }
    }

    .DayPicker-NavBar {
      position: absolute;
      right: 0;
      top: -0.5rem;
    }

    .DayPicker-NavButton {
      height: 2.5em;
      opacity: 0.3;
      width: 2.5em;
    }

    .DayPicker-NavButton--prev {
      margin-right: 2.5em;
    }

    .DayPicker-wrapper {
      padding-top: 2.1rem;

      &:focus {
        outline: none;
      }
    }

    .DayPicker-Caption {
      left: 1rem;
    	margin-left: 1.3rem;
    	padding: 0;
      position: absolute;
      top: 1rem;
    }

    .DayPicker-Day {
      font-size: 1.4rem;
      padding: 1.2rem;
      position: relative;

      &:focus {
        outline: none;
      }
    }

    .DayPicker-Caption,
    .DayPicker-Day {
      color: #454545;
    }

    .DayPicker-Day--today {
      color: #009068;
    }

    .DayPicker-Day--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .DayPicker-Caption > div {
      font-size: 1.6rem;
    }

    .DayPicker-Weekday {
      color: #bbb;
      font-size: 1.4rem;
    }

    .DayPicker-Day--outside {
      color: #bbb;
    }
  }
`;

const SelectedDate = styled.span`
  display: block;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    max-width: 16rem;
  }
`;

const FixedInfoArea = styled.div`
  background: #f9f9f9;
  bottom: 5.7rem;
  box-shadow: 0 -0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  left: 0;
  padding: 2rem;
  position: fixed;
  width: 100%;
`;

export default class Calendar extends Component {
  state = {
    selectedDay: undefined
  }

  componentDidMount = () => {
    const selectedDay = `${new Date().toLocaleDateString('en-US')}`;
    this.setState({ selectedDay });
  }

  handleDayClick = (day, { selected }) => {
    if (!selected) {
      const selectedDay = `${day.toLocaleDateString('en-US')}`;
      this.setState({ selectedDay });
    }
  }

  toggleWater = () => {
    const day = this.state.selectedDay;
    const existingDays = this.props.wateredDates;
    let updatedState = null;

    if (existingDays.includes(day)) {
      updatedState = existingDays.filter(currentDay => currentDay !== day);
    } else {
      updatedState = [...existingDays, day];
    }

    this.props.updateWateringState(updatedState);
  }

  toggleFertilised = () => {
    const day = this.state.selectedDay;
    const existingDays = this.props.fertilisedDates;
    let updatedState = null;

    if (existingDays.includes(day)) {
      updatedState = existingDays.filter(currentDay => currentDay !== day);
    } else {
      updatedState = [...existingDays, day];
    }

    this.props.updateFertilisedState(updatedState);
  }

  getCurrentDayInfo = () => {
    if (this.state.selectedDay) {
      const wateredChecked = this.props.wateredDates.includes(this.state.selectedDay);
      const fertilisedChecked = this.props.fertilisedDates.includes(this.state.selectedDay);

      return (
        <FixedInfoArea>
          <SelectedDate>{format(this.state.selectedDay, 'ddd, Do MMMM')}</SelectedDate>
          <CheckBoxContainer>
            <CheckboxPill>
              <input id="watered" type="checkbox" onChange={this.toggleWater} checked={wateredChecked} />
              <label htmlFor="watered">Watered</label>
            </CheckboxPill>
            <CheckboxPill>
              <input id="fertilised" type="checkbox" onChange={this.toggleFertilised} checked={fertilisedChecked} />
              <label htmlFor="fertilised">Fertilised</label>
            </CheckboxPill>
          </CheckBoxContainer>
        </FixedInfoArea>
      )
    }

    return null;
  }

  render() {
    const modifiers = {
      watered: this.props.wateredDates.map(day => new Date(day)),
      fertilised: this.props.fertilisedDates.map(day => new Date(day))
    };

    const currentDayContainer = this.getCurrentDayInfo();
    const today = new Date();

    return (
      <CalendarContainer>
        <DayPicker
          onDayClick={this.handleDayClick}
          selectedDays={new Date(this.state.selectedDay)}
          modifiers={modifiers}
          className="calendar-container"
          showOutsideDays
          fixedWeeks
          disabledDays={{ after: today }}
        />
        {currentDayContainer}
      </CalendarContainer>
    )
  }
}
