import React, { useState } from 'react';
import styled from 'styled-components';
import { H3, P, TextLink, TextButton } from '../styles/Common.js';
import { format } from 'date-fns';

const SubscriptionArea = styled.div`
  margin-bottom: 8rem;
`;

const CancelCheck = styled.div`
  background: rgba(0, 128, 128, 0.1);
  padding: 2rem;
  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    button {
      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }
`;

export default ({
  cancelSubscription,
  isSubmitting,
  user
}) => {
  if (!window.config.enableSubscriptions) return null;
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  const {
    currentPeriodEnd,
    stripeSubscriptionId,
    isSubscribed
  } = user;

  let subStatus = false;
  if (isSubscribed) subStatus = 'subscribed';
  if (!stripeSubscriptionId && isSubscribed) subStatus = 'cancelled-in-period';
  if (!isSubscribed) subStatus = 'not-subscribed';

  return (
    <SubscriptionArea>
      <H3>Subscription</H3>
      {subStatus === 'subscribed' &&
        <>
          <P>Thanks for subscribing, your support is appreciated and allows me to continue to run this app <span role="img" aria-label="Plant emoji">🌱</span></P>
          {showCancelConfirm ? (
            <CancelCheck>
              <p>Are you sure you want to cancel?</p>
              <div>
                {isSubmitting ? <p>Cancelling</p> :
                  <>
                    <TextButton onClick={() => setShowCancelConfirm(false)}>No, keep subscription</TextButton>
                    <TextButton onClick={() => cancelSubscription()}>Yes, cancel subscription</TextButton>
                  </>
                }
              </div>
            </CancelCheck>
          ) : (
            <TextButton onClick={() => setShowCancelConfirm(true)}>
              {isSubmitting ? 'Loading' : 'Cancel subscription'}
            </TextButton>
          )}
        </>
      }
      {subStatus === 'cancelled-in-period' &&
        <>
          <P>You have cancelled your subscription</P>
          <P>Your subscription will end on {format(currentPeriodEnd, 'ddd, Do MMMM')}</P>
        </>
      }
      {subStatus === 'not-subscribed' &&
        <>
          <P>You aren't subscibed yet.</P>
          <TextLink to="/subscribe">See what features you're missing out on</TextLink>
        </>
      }
    </SubscriptionArea>
  );
};
