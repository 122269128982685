import React from 'react';
import BaseForm from '../BaseForm.jsx';
import PlantTitleGroup from '../PlantTitleGroup.jsx';
import PasswordInput from '../inputs/PasswordInput.jsx';
import Button from '../Button.jsx';
import { AuthFormSmallLink, Form } from '../../styles/Common.js';
import { Link } from 'react-router-dom';

export default class RegisterForm extends BaseForm {
  state = {
    showValidation: false,
    fields: {
      password: {
        value: '',
        isValid: false
      }
    }
  }

  render() {
    const { fields, showValidation } = this.state;

    return (
      <>
        <PlantTitleGroup name="Update Password" />

        <Form onSubmit={this.handleSubmit} noValidate>
          <PasswordInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.password.value}
          />

          <Button isSubmitting={this.props.isSubmitting}>Submit</Button>
        </Form>

        <AuthFormSmallLink>
          <Link to="/log-in">Log in</Link>
        </AuthFormSmallLink>
      </>
    )
  }
};
