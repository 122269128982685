import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  background: #fff;
  border-bottom: 0.1rem solid #eee;
  margin: 0 auto;
  max-width: 37rem;

  @media (max-width: 420px) {
    max-width: 100%;
  }

  @media (max-width: 370px) {
    max-width: 100%;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;

    li {
      flex: 1 1 auto;
    }

    a {
      color: teal;
      display: block;
      height: 100%;
      padding: 1.6rem 1rem;
      text-align: center;
      text-decoration: none;
    }
  }
`;

export default ({ isAuthed }) => {
  if (!isAuthed) return null;

  return (
    <Nav className="main-nav">
      <ul>
        <li><NavLink exact activeStyle={{borderBottom: '0.2rem solid teal'}} to="/">All Plants</NavLink></li>
        <li><NavLink exact activeStyle={{borderBottom: '0.2rem solid teal'}} to="/plant/add">New Plant</NavLink></li>
      </ul>
    </Nav>
  )
};
