import React, { Component } from 'react';
import RequestPasswordResetForm from '../components/forgotPassword/RequestPasswordResetForm.jsx';
import RequestPasswordResetSent from '../components/forgotPassword/RequestPasswordResetSent.jsx';
import api from '../api.js';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

export default class ForgotPasswordPage extends Component {
  state = {
    isSubmitting: false,
    emailSent: false
  }

  requestPasswordReset = fields => {
    this.setState({ isSubmitting: true });

    const rootUrl = window.location.origin;

    const data = { ...fields, rootUrl };

    api.requestPasswordReset(data).then(() => {
      this.setState({ emailSent: true });
    })
    .catch(error => {
      console.log('⛔️ ', error);
      toast.error(error.message);
    })
    .finally(() => this.setState({ isSubmitting: false }));
  }

  render() {
    if (this.props.isAuthed) return <Redirect to="/" />;
    if (this.state.emailSent) return <RequestPasswordResetSent />
    return <RequestPasswordResetForm onSubmit={this.requestPasswordReset} isSubmitting={this.state.isSubmitting} />;
  }
}
