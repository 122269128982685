import React, { Component } from 'react';
import RegisterForm from '../components/RegisterForm.jsx';
import HOCAuthed from '../components/HOCAuthed.js';

const RegisterComponent = class RegisterPage extends Component {
  register = user => {
    this.props.action('register', user);
  }

  render() {
    return <RegisterForm onSubmit={this.register} />;
  }
}

export default HOCAuthed(RegisterComponent);
