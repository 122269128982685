import React from 'react';
import PlantTitleGroup from '../PlantTitleGroup.jsx';

export default () => {
  return (
    <div>
      <PlantTitleGroup name="Success" />
      <p>An email has been sent with a link to reset your password. Please make sure to check your junk folder if you don't see the email.</p>
    </div>
  );
}
