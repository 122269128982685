import React from 'react';
import { getLastWateredDate, getLastFertilisedDate } from '../helpers.js';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { H2 } from '../styles/Common.js';

const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  a {
    color: #009068;
    font-size: 1.2rem;
    padding: 0.6rem 0 0.6rem 0.6rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Inner = styled.div`
  flex: 1 1 auto;

  > * {
    margin-top: 0.5rem

    &:first-child {
      margin-top: 0;
    }
  }
`;

const Room = styled.span`
  color: #7f7f80;
  font-size: 1.2rem;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;

  > span {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const LastWatered = styled.span`
  font-size: 1.1rem;
  background: #f0f3ff;
  color: #7299ff;
  border-radius: 1rem;
  padding: 0.4rem 0.8rem;
`;

const LastFertilised = styled.span`
  font-size: 1.1rem;
  background: #fbf3e9;
  color: #da9952;
  border-radius: 1rem;
  padding: 0.4rem 0.8rem;
`;

const Name = styled(H2)`
  margin-bottom: 0;
`;

export default ({
  edit,
  fertilisedDates,
  location,
  name,
  wateredDates
}) => {
  let lastWatered = false;
  let lastFertilised = false;
  let tags = wateredDates || fertilisedDates;

  if (wateredDates) lastWatered = getLastWateredDate(wateredDates);
  if (fertilisedDates) lastFertilised = getLastFertilisedDate(fertilisedDates);

  return (
    <TitleContainer>
      <Inner>
        <Name>{name}</Name>
        {location && <Room>{location}</Room>}
        {tags &&
          <Tags>
            {lastWatered && <LastWatered>{lastWatered}</LastWatered>}
            {lastFertilised && <LastFertilised>{lastFertilised}</LastFertilised>}
          </Tags>
        }
      </Inner>
      {edit && <Link to={`/plant/edit/${edit}`}>Edit</Link>}
    </TitleContainer>
  );
}
