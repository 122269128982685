import React from 'react';
import BaseForm from './BaseForm.jsx';
import PlantTitleGroup from './PlantTitleGroup.jsx';
import PlantNameInput from './inputs/PlantNameInput.jsx';
import PlantLocationInput from './inputs/PlantLocationInput.jsx';
import Button from './Button.jsx';
import { Form } from '../styles/Common.js';

export default class AddPlantForm extends BaseForm {
  state = {
    showValidation: false,
    fields: {
      name: {
        value: '',
        isValid: false
      },
      location: {
        value: '',
        isValid: false
      }
    }
  }

  render() {
    const { fields, showValidation } = this.state;

    return (
      <div>
        <PlantTitleGroup
          name="Add Plant"
          location="You can add more details later"
        />

        <Form onSubmit={this.handleSubmit} noValidate>
          <PlantNameInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.name.value}
          />

          <PlantLocationInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.location.value}
          />

          <Button isSubmitting={this.props.isSubmitting}>Add Plant</Button>
        </Form>
      </div>
    );
  }
}
