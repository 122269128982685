import React from 'react';
import styled from 'styled-components';
import { H3 } from '../styles/Common.js';
import BoP from '../assets/icons/bird-of-paradise.svg';
import Monstera from '../assets/icons/monstera.svg';
import Ficus from '../assets/icons/ficus.svg';

const PricingContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 0 3rem;
  position: relative;

  & + section {
    padding: 3rem 0 0;

    &::before {
      background: rgba(0, 128, 128, 0.2);
      content: '';
      height: 0.1rem;
      left: -3rem;
      position: absolute;
      top: 0;
      width: calc(100% + 6rem);
    }
  }


  button {
    margin-bottom: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 2rem;
`;

const Icon = styled.span`
  display: block;

  img {
    height: 6rem;
    width: 6rem;
  }
`;

const Tick = styled.span`
  margin: 0.4rem 1rem 0 0;

  &::before,
  &::after {
    background: #10cbad;
    content: '';
    display: block;
    height: 0.4rem;
  }

  &::before {
    transform: rotate(45deg) translate(0.3rem, 0.2rem);
    width: 0.8rem;
  }

  &::after {
    transform: rotate(-45deg) translate(0.5rem, 0.1rem);
    width: 1.2rem;
  }
`;

const List = styled.ul`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListItem = styled.li`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  span {
    line-height: 1.2;
  }
`;

const ListItemSoon = styled(ListItem)`
  color: teal;
  opacity: 0.6;
  position: relative;
`;

const Price = styled.span`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0.6rem;
  position: relative;
  text-transform: uppercase;
`;

const Currency = styled.span`
  font-size: 1.2rem;
  left: -1rem;
  margin-top: 0.5rem;
  position: absolute;
  top: 0;
`;

const PerMonth = styled.span`
  font-size: 1.2rem;
  margin: 0.2rem 0 0 0.2rem;
  text-transform: lowercase;
`;

const PlanTitle = styled(H3)`
  border-radius: 3rem;
  padding: 0.6rem 2rem;
  margin-bottom: 2rem;
`;

const PricePerDay = styled.p`
  color: #505050;
  font-size: 1.2rem;
`;

const Description = styled.p`
  color: #7b7b7b;
  font-size: 1.4rem;
  line-height: 1.3;
  margin: 0 0 2rem;
  text-align: center;
`;

const ComingSoon = styled.p`
  color: teal;
  font-weight: 700;
`;

const PlantTitleBasic = styled(PlanTitle)`
  background: teal;
  color: #fff;
`;

const PlantTitlePro = styled(PlanTitle)`
  background: gold;
  color: #222;
`;

const getIcons = (icons) => {
  const IconsMap = {
    'BoP': BoP,
    'Monstera': Monstera,
    'Ficus': Ficus
  };

  return (
    <IconContainer>
      {icons.map((icon, i) => <Icon key={i}><img src={IconsMap[icon]} alt={icon} /></Icon>)}
    </IconContainer>
  );
}

const getFeaturesList = (features, paidPlan) => {
  const list = features.filter(item => !item.soon);

  if (!list.length) return null;

  const featuresList = list.map(({ text, soon }, i) => (
    <ListItem key={i}><Tick /><span>{text}</span></ListItem>
  ));

  return (
    <List>
      {paidPlan ? <ListItem key="123"><Tick />Help support me and this project</ListItem> : null}
      {featuresList}
    </List>
  );
}

const getComingSoonList = (features) => {
  const list = features.filter(item => item.soon);

  if (!list.length) return null;

  const featuresList = list.map(({ text, soon }, i) => (
    <ListItemSoon key={i}><Tick /><span>{text}</span></ListItemSoon>
  ));

  return (
    <>
      <ComingSoon>Coming soon</ComingSoon>
      <List>{featuresList}</List>
    </>
  );
}

export default({
  title = false,
  price = false,
  pricePerDay = false,
  description,
  features,
  icons,
  children
}) => {
  let titleEl = null;
  if (title) titleEl = <PlantTitleBasic>{title}</PlantTitleBasic>;
  if (price) titleEl = <PlantTitlePro>{title}</PlantTitlePro>;

  const descriptionEl = <Description>{description}</Description>
  const iconsList = getIcons(icons);
  const priceEl = price ? <Price><Currency>$</Currency>{price}<PerMonth>per month</PerMonth></Price> : <Price>Free</Price>;
  const pricePerDayEl = pricePerDay ? <PricePerDay>{pricePerDay}</PricePerDay> : null;
  const featuresList = getFeaturesList(features, !!price);
  const soonList = getComingSoonList(features);

  return (
    <PricingContainer>
      {titleEl}
      {iconsList}
      {priceEl}
      {pricePerDayEl}
      {descriptionEl}
      {featuresList}
      {soonList}
      {children || null}
    </PricingContainer>
  );
};
