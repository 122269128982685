import React from 'react';
import BaseForm from './BaseForm.jsx';
import PlantTitleGroup from './PlantTitleGroup.jsx';
import EmailInput from './inputs/EmailInput.jsx';
import PasswordInput from './inputs/PasswordInput.jsx';
import Button from './Button.jsx';
import { AuthFormSmallLink, Form } from '../styles/Common.js';
import { Link } from 'react-router-dom';

export default class RegisterForm extends BaseForm {
  state = {
    showValidation: false,
    fields: {
      email: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    }
  }

  render() {
    const { fields, showValidation } = this.state;

    return (
      <>
        <PlantTitleGroup name="Log in" />

        <Form onSubmit={this.handleSubmit} noValidate>
          <EmailInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.email.value}
          />

          <PasswordInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.password.value}
          />

          <Button>Login</Button>
        </Form>

        <AuthFormSmallLink>
          <Link to="/register">Register</Link>
          <Link to="/forgotpassword">Forgot password?</Link>
        </AuthFormSmallLink>
      </>
    )
  }
};
