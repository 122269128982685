import React from 'react';
import PlantTitleGroup from '../PlantTitleGroup.jsx';
import { AuthFormSmallLink } from '../../styles/Common.js';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div>
      <PlantTitleGroup name="Success" />
      <p>Your password has been updated</p>
      <AuthFormSmallLink>
        <Link to="/log-in">Log in</Link>
      </AuthFormSmallLink>
    </div>
  );
}
