import React from 'react';
import BaseForm from '../BaseForm.jsx';
import PlantTitleGroup from '../PlantTitleGroup.jsx';
import EmailInput from '../inputs/EmailInput.jsx';
import Button from '../Button.jsx';
import { AuthFormSmallLink, Form } from '../../styles/Common.js';
import { Link } from 'react-router-dom';

export default class RegisterForm extends BaseForm {
  state = {
    showValidation: false,
    fields: {
      email: {
        value: '',
        isValid: false
      }
    }
  }

  render() {
    const { fields, showValidation } = this.state;

    return (
      <>
        <PlantTitleGroup name="Forgot Password" />

        <p>Enter your email below and we'll send you a link to reset your password</p>

        <Form onSubmit={this.handleSubmit} noValidate>
          <EmailInput
            onFieldValidate={this.onFieldValidate}
            onChange={this.onChange}
            showValidation={showValidation}
            value={fields.email.value}
          />

          <Button isSubmitting={this.props.isSubmitting}>Submit</Button>
        </Form>

        <AuthFormSmallLink>
          <Link to="/log-in">Log in</Link>
        </AuthFormSmallLink>
      </>
    )
  }
};
