import React from 'react';
import InputGroup from '../InputGroup.jsx';

export default ({
  onFieldValidate,
  onChange,
  showValidation,
  value
}) => (
  <InputGroup
    label="Password"
    placeholder="Password"
    type="password"
    onFieldValidate={onFieldValidate}
    onChange={onChange}
    showValidation={showValidation}
    stateName="password"
    value={value}
    validation={{
      required: true,
      numberRange: {
        min: 8,
        max: 30
      }
    }}
    attributes={{
      'data-hj-masked': '',
      autoCorrect: 'off',
      autoComplete: 'off',
      required: true
    }}
  />
);
