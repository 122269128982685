import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg`
  cursor: pointer;
  display: block;
  fill: #fff;
  height: 2rem;
  width: 2.2rem;

  path,
  circle {
    fill: #fff;
  }

  &:hover {
    path,
    circle {
      opacity: 0.7;
    }
  }
`;

export default ({ onClick }) => (
<Icon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 55 53" xmlSpace="preserve"><path fill="#000000" d="M8.908,47.913c0-8.672,5.656-15.7,12.634-15.7h11.404c6.979,0,12.634,7.028,12.634,15.7"/><circle fill="#000000" cx="27.244" cy="16.296" r="10.615"/></Icon>
);
