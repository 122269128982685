import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

const HOCAuthed = (WrappedComponent) => {
  class AuthedComponent extends Component {
    state = {
      redirect: false
    }

    action = (action, user) => {
      this.setState({ isSubmitting: true });

      this.props.authorise(action, user)
      .then(() => this.setState({ redirect: true }))
      .finally(() => this.setState({ isSubmitting: false }));
    }

    render() {
      if (this.state.redirect) return <Redirect to="/" />;
      if (this.props.isAuthed) return <Redirect to="/" />;

      return (
        <WrappedComponent
          {...this.props}
          action={this.action}
        />
      )
    }
  }

  return AuthedComponent;
};

export default HOCAuthed;
