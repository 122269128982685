import { track } from './tracking.js';
import axios from 'axios';
const liveAPI = 'https://house-plant-api.herokuapp.com';
const localAPI = 'http://localhost:3000';
const api = window.location.host === 'app.houseplanttracker.com' ? liveAPI : localAPI;

export default {
  fetchData({ method = 'post', path = '', data = {}, token }) {
    return new Promise((resolve, reject) => {
      axios({
        method,
        url: `${api}/${path}`,
        data,
        headers: {
          Authorization: token
        }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  },

  // Stripe
  getStripeSessionId(data) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        method: 'post',
        path: 'stripesessionid',
        data
      })
      .then(data => {
        track('Subscription', 'Get Stripe session ID', 'Get ID successful');
        resolve(data);
      })
      .catch(error => {
        track('Subscription', 'Get Stripe session ID', 'Get ID unsuccessful');
        reject(error);
      });
    })
  },

  cancelStripeSubscription(data) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        method: 'post',
        path: 'stripecancel',
        data
      })
      .then(data => {
        track('Subscription', 'Cancel', 'Cancel successful');
        resolve(data);
      })
      .catch(error => {
        track('Subscription', 'Cancel', 'Cancel unsuccessful');
        reject(error);
      });
    })
  },

  // General endpoints
  addPlant(userId, plant, token) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        path: 'plant',
        data: { plant, userId },
        token
      })
      .then(data => {
        track('Plant', 'Add', 'Add successful');
        resolve(data);
      })
      .catch(error => {
        track('Plant', 'Add', 'Add unsuccessful');
        reject(error);
      });
    })
  },

  updatePlant(userId, plant, token) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        method: 'patch',
        path: `plant/${plant.id}`,
        data: { plant, userId },
        token
      })
      .then(data => {
        track('Plant', 'Update', 'Update successful');
        resolve(data);
      })
      .catch(error => {
        track('Plant', 'Update', 'Update unsuccessful');
        reject(error);
      });
    })
  },

  // Authed endpoints
  register(user) {
    return new Promise((resolve, reject) => {
      this.fetchData({ path: 'register', data: user })
        .then(data => {
          track('User', 'Register', 'Register successful');
          resolve(data);
        })
        .catch(error => {
          track('User', 'Register', 'Register unsuccessful');
          reject(error)
        });
    });
  },

  login(user) {
    return new Promise((resolve, reject) => {
      this.fetchData({ path: 'login', data: user })
        .then(data => {
          track('User', 'Log in', 'Login successful');
          resolve(data);
        })
        .catch(error => {
          track('User', 'Log in', 'Login failed');
          reject(error);
        });
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      this.fetchData({ method: 'delete', path: 'logout' })
        .then(data => {
          track('User', 'Log out', 'Log out successful');
          resolve(data);
        })
        .catch(error => {
          track('User', 'Log out', 'Log out unsuccessful');
          reject(error);
        });
    });
  },

  getUser(userId, token) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        method: 'get',
        path: `user/${userId}`,
        token
      })
        .then(data => resolve(data))
        .catch(error => reject(error));
    })
  },

  requestPasswordReset(data) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        path: 'forgotpassword',
        data
      })
      .then(data => {
        track('User', 'Request password reset', 'Request reset successful');
        resolve(data);
      })
      .catch(error => {
        track('User', 'Request password reset', 'Request reset unsuccessful');
        reject(error);
      });
    });
  },

  updatePasswordViaEmail(data) {
    return new Promise((resolve, reject) => {
      this.fetchData({
        path: 'resetpasswordviaemail',
        data
      })
      .then(data => {
        track('User', 'Password update via email', 'Password reset successful');
        resolve(data);
      })
      .catch(error => {
        track('User', 'Password update via email', 'Password reset unsuccessful');
        reject(error);
      });
    });
  }
}
