import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InnerContainer = styled.div`
  background: #fff;
  margin: 0 auto 15rem;
  max-width: 37rem;
  padding: 3rem;
  position: relative;

  @media (max-width: 420px) {
    max-width: 100%;
  }

  @media (max-width: 370px) {
    max-width: 100%;
    padding: 2rem;
  }
`;

export const SaveChangesBtn = styled.button`
  background: #e9f4ed;
  border: none;
  border-radius: 4rem;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 144, 104, 0.3);
  color: #009068;
  cursor: pointer;
  font-size: 2rem;
  margin-top: 3rem;
  padding: 1.6rem 2rem;
  width: 100%;

  &:hover {
    background: #f5faf7;
  }

  &:focus {
    outline: none;
  }
`;

export const Form = styled.form`
  div:last-of-type {
    margin-bottom: 0;
  }
`;

export const AuthFormSmallLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  text-align: center;

  a {
    color: #009068;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #09b383;
    }
  }
`;

export const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1.1;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

export const H3 = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const P = styled.p`
  font-size: 1.6rem;
  line-height: 1.2;
`;

export const TextLink = styled(Link)`
  color: teal;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  color: teal;
  cursor: pointer;
  display: block;
  font-size: 1.6rem;
  height: 100%;
  line-height: 1;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const CTAButton = styled.button`
  background: #04c6b9;
  background: linear-gradient(to right,  #04c6b9 0%,#24d991 100%);
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  cursor: pointer;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  padding: 1.8rem 2rem;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0.2rem 0.1rem hsla(176, 90%, 30%, 0.6);
  width: 100%;

  &:hover {
    background: #21af60;
  }
`;

export const FixedFooterButton = styled.div`
  background: #fff;
  bottom: 0;
  box-shadow: 0 -0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  left: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 10;

  button {
    border-radius: 0;
    margin: 0;
    width: 100%;
  }
`;

export const CheckboxPill = styled.div`
  flex-grow: 1;

  &:first-child {
    margin-right: 2rem;
  }

  input {
    display: none;

    &:checked + label {
      background: teal;
      color: #fff;
    }
  }

  label {
    background: #eee;
    border-radius: 4rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 1.4rem 2rem;
    user-select: none;

    &:hover {
      background: #e6e6e6;
    }
  }
`;
