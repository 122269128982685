import { distanceInWordsToNow, differenceInCalendarDays } from 'date-fns';

export const getLastWateredDate = (wateredDates) => {
  let lastWatered = 'Never watered';
  if (wateredDates.length) {
    const sortedWater = wateredDates.map(day => new Date(day)).sort((a, b) => b - a);
    const wateredToday = differenceInCalendarDays(sortedWater[0], new Date()) === 0;
    lastWatered = wateredToday ? 'Watered today' : `${distanceInWordsToNow(sortedWater[0])} ago`;
  }
  return lastWatered || 'Set last watered date';
}

export const getLastFertilisedDate = (fertilisedDates) => {
  let lastFertilised = 'Never fertilised';
  if (fertilisedDates.length) {
    const sortedFertilised = fertilisedDates.map(day => new Date(day)).sort((a, b) => b - a);
    const fertilisedToday = differenceInCalendarDays(sortedFertilised[0], new Date()) === 0;
    lastFertilised = fertilisedToday ? 'Fertilised today' : `${distanceInWordsToNow(sortedFertilised[0])} ago`;
  }
  return lastFertilised || 'Set last fertilised date';
}

// https://gomakethings.com/check-if-two-arrays-or-objects-are-equal-with-javascript/
export const isEqual = (value, other) => {
	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {

		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!isEqual(item1, item2)) return false;
		}

		// Otherwise, do a simple comparison
		else {

			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}

		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;
}

export const getQueryStringParameter = (name, fromString, str = '') => {
  const paramName = name.replace(/[[\]]/g, '\\$&');
  const url = fromString ? str : window.location.href;
  const regString = fromString ? '' : '[?&]';
  const regex = new RegExp(`${regString}${paramName}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
