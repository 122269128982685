import React, { Component } from 'react';
import ForgotPasswordResetForm from '../components/forgotPassword/ForgotPasswordResetForm.jsx';
import ForgotPasswordResetSuccess from '../components/forgotPassword/ForgotPasswordResetSuccess.jsx';
import api from '../api.js';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

export default class ForgotPasswordPage extends Component {
  state = {
    isSubmitting: false,
    passwordUpdated: false
  }

  updatePasswordViaEmail = fields => {
    this.setState({ isSubmitting: true });

    const data = { ...fields, resetPasswordToken: this.props.resetPasswordToken };

    api.updatePasswordViaEmail(data).then(() => {
      this.setState({ passwordUpdated: true });
    })
    .catch(error => {
      console.log('⛔️ ', error);
      toast.error(error.message);
    })
    .finally(() => this.setState({ isSubmitting: false }));
  }

  render() {
    if (this.props.isAuthed) return <Redirect to="/" />;
    if (this.state.passwordUpdated) return <ForgotPasswordResetSuccess />
    return <ForgotPasswordResetForm onSubmit={this.updatePasswordViaEmail} isSubmitting={this.state.isSubmitting} />;
  }
}
